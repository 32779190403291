import { render, staticRenderFns } from "./klarna.vue?vue&type=template&id=826df1be&scoped=true&"
import script from "./klarna.vue?vue&type=script&lang=js&"
export * from "./klarna.vue?vue&type=script&lang=js&"
import style0 from "./klarna.vue?vue&type=style&index=0&id=826df1be&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "826df1be",
  null
  
)

export default component.exports